<template>
    <v-app>


        <v-card>
            
            <v-card-text>
          <v-card outlined tile elevation="1">
            <v-card-text style="padding-bottom: 0px;">
              <div class="d-flex justify-space-between mb-4">
   
                <v-autocomplete
                v-model="e6"
                :items="storesAccess"
                chips
                clearable
                deletable-chips
                label="Stores"
                multiple
                item-text="name"
                item-value="value"
                v-on:change="checkSelection"
            >

              <template v-slot:item="data">
                <template v-if="Array.isArray(data.item.value)">
                  <v-list-item-content v-text="'Seleccionar todo '+ data.item.group"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>

            </v-autocomplete>

            <v-btn class="btn-starkoms-primary" dark style="min-width: 138px; margin-top:20px;" @click="fetchDataBiStores" >Cargar BI</v-btn>
              </div>
              
           
            
            </v-card-text>
          </v-card>
        </v-card-text>

            
            <v-card-title>
                <v-spacer></v-spacer>
                <!-- <v-btn class @click="fetchData" text icon color="blue">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn> -->
            </v-card-title>
            <v-card>
                
                
                <iframe
                        :src="iframeUrl"
                        frameborder="0"
                        width="100%"
                        height="1000"
                        allowtransparency
                    ></iframe>

              

            </v-card>
        </v-card>
    </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
    mounted(){
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Dashboard BI", route: "dashboard-bi" },
        ]);
        this.fetchStores();
        // this.getiIframe(stores);
        
    },
    data(){
        return{
            iframeUrl:'',
            tableData: [],
            storesSortedPre: [],
            storesSorted: [],
            storesGroups: [],
            e6: [],
            e7: [],
            // states: [
            // { header: 'Group 1' },
            // { name: 'Select All', value: [1,2,3,4],  group: 'Group 1' },
            // { name: 'Sandra Adams', value: 1,  group: 'Group 1' },
            // { name: 'Ali Connors', value: 2,  group: 'Group 1' },
            // { name: 'Trevor Hansen', value: 3,  group: 'Group 1' },
            // { name: 'Tucker Smith', value: 4,  group: 'Group 1' },
            // { divider: true },
            // { header: 'Group 2' },
            // { name: 'Britta Holt', value: 5, group: 'Group 2' },
            // { name: 'Jane Smith ', value: 6,  group: 'Group 2' },
            // { name: 'John Smith', value: 7,  group: 'Group 2' },
            // { name: 'Sandra Williams', value: 8,  group: 'Group 2' },
            // ],
             storesAccess: [],
        }
    },
    methods:{
        checkSelection(){
            var vm = this;
            
            vm.e6.forEach(elem => {
                // let val = data[key] // value of the current key

                if(Array.isArray(elem)){
                    vm.e6.pop();
                    var isRemove = true;
                    elem.forEach(inelem => {
                        if(!vm.e6.includes(inelem)){
                            console.log(inelem);
                            vm.e6.push(inelem);
                            isRemove = false;
                        }
                    });

                    console.log(isRemove);
                    if(isRemove){
                        elem.forEach(inelem => {
                            var index = vm.e6.indexOf(inelem);
                            if (index !== -1) {
                                vm.e6.splice(index, 1);
                            }
                        });
                        
                    }
                    
                }

            });
        },
        fetchDataBiStores(){
           var vm = this;
           console.log(vm.e6); 
           vm.getiIframe();
        },
        getiIframe(){
            var vm = this;
            vm.loading = true;
            
            // you will need to install via 'npm install jsonwebtoken' or in your package.json
            var jwt = require("jsonwebtoken");                
            this.axios({
                url: 'admin/configurations',
                params: {
                    name : "METABASE_INFO_GROUP_STORES"
                },
                method: 'GET'
            }).then( response => {
                if (response.data.METABASE_SITE_URL != undefined) {
                    var METABASE_SITE_URL = response.data.METABASE_SITE_URL;
                    var METABASE_SECRET_KEY = response.data.METABASE_SECRET_KEY;

                    var payload = {
                    resource: { dashboard: parseInt(response.data.METABASE_DASHBOARD_ID) },
                    params: {
                        "store_id": vm.e6
                    },
                    exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
                    };
                    var token = jwt.sign(payload, METABASE_SECRET_KEY);
                    vm.iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=false&titled=false";
                }else{
                    vm.$bvToast.toast(
                        'Error al traer la configuración',
                        {
                            title: `Notificación`,
                            variant: "danger",
                            solid: true,
                            toaster: "b-toaster-bottom-center",
                        }
                    );
                }                
                vm.loading = false;
            })
        },
        fetchStores(){
            var vm = this;
            
            this.axios({
                url: 'admin/stores/all',
                method: 'GET'
            }).then( response => {
                vm.tableData = response.data;

                vm.tableData.forEach(elem => {
                    if(elem.store_group != null){

                        if(!vm.storesGroups.includes(elem.store_group.name)){
                            var in_stores = [];
                            vm.storesSortedPre.push({ divider: true });
                            vm.storesSortedPre.push({ header: elem.store_group.name });
                            vm.tableData.forEach(elem_in => {
                                if(elem_in.store_group.name  ==  elem.store_group.name){
                                    in_stores.push(elem_in.id);
                                }
                            });
                            vm.storesSortedPre.push({ name: 'Select All', value: in_stores,  group: elem.store_group.name });
                            vm.storesGroups.push(elem.store_group.name);
                        }

                        var store = {
                            name: elem.name,
                            value: elem.id,
                            group: elem.store_group.name
                        };
     
                        vm.storesSortedPre.push(store);
                    }
                    // else{
                    //     console.log('dssss');
                    //     console.log(elem.name);
                    //     if(!vm.storesGroups.includes('Sin grupo')){
                    //         var in_stores = [];
                    //         vm.storesSortedPre.push({ header: 'Sin grupo' });
                    //         vm.tableData.forEach(elem_in => {
                    //             if(elem_in.store_group.name  ==  'Sin grupo'){
                    //                 in_stores.push(elem_in.id);
                    //             }
                    //         });
                    //         vm.storesSortedPre.push({ name: 'Select All', value: in_stores,  group: 'Sin grupo' });
                    //         vm.storesGroups.push('Sin grupo');
                    //     }

                    //     var store = {
                    //         name: elem.name,
                    //         value: elem.id,
                    //         group: 'Sin grupo'
                    //     };
                    //     console.log(store);
                    //     vm.storesSortedPre.push(store);
                    // }
                    
                    // vm.storesSorted[]

                });
            
                vm.storesAccess = vm.storesSortedPre;
            }).catch( error => {
                console.log(error);
            })
            
        },
    }
}
</script>

<style>
.v-autocomplete__content .v-subheader.theme--light {
    color: #fa5711;
    font-size: 14px;
    font-weight: 600;
}
.v-autocomplete__content .v-list-item__content {
   font-size: 12px;
}

.v-autocomplete__content .v-list-item__content .v-list-item__title{font-size: 12px;}

</style>